@tailwind base;
@tailwind components;
@tailwind utilities;

.space-grotesk {
    font-family: "Space Grotesk", sans-serif;
}

.duotone video {
    filter: url(#duotone);
}

.player > video {
    object-position: center;
    object-fit: cover;
}
_::-webkit-full-page-media,
_:future,
:root .duotone video {
    filter: grayscale(100%);
}
